import { useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import { useFormik } from 'formik';
import { navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';

import {
  GetUserOnProfilePageDocument,
  UpdatePersonalInformationDocument,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import SelectAndTextInput from '@components/form/SelectAndTextInput';
import { SelectInputValue } from '@components/form/SelectInput';
import TextInput from '@components/form/TextInput';

import { countryValues } from '@data/countries';

import { getErrorMessages, getInvalidFields } from '@lib/errors';

import Menu from './Menu';

const ProfilePage: FC<PageProps> = ({ location }) => {
  const personalInformationQuery = useQuery(GetUserOnProfilePageDocument);

  const [updatePersonalInformation] = useMutation(
    UpdatePersonalInformationDocument
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      companyName: '',
      companyAddress: '',
      companyActivity: '',
      jobTitle: '',
      email: '',
      phoneNumber: '',
      country: undefined as (typeof countryValues)[0],
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      companyName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phoneNumber: Yup.string()
        .matches(
          /^\+?(?:[0-9] ?){6,14}[0-9]$/,
          'Please provide a valid phone number.'
        )
        .required('Required'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const toastId = toast.loading('Updating profile...');
      try {
        await updatePersonalInformation({
          variables: {
            userUpdateInput: {
              name: values.name,
              companyName: values.companyName,
              companyAddress: values.companyAddress,
              companyActivity: values.companyActivity,
              jobTitle: values.jobTitle,

              email: values.email,
              phoneNumber: values.phoneNumber,
              country: values.country.value.isoCode,
            },
          },
        });

        toast.success('Profile updated!', { id: toastId });
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        toast.error(errorMessages?.join('\n') ?? 'Failed to update profile', {
          id: toastId,
          duration: 10000,
        });
        if (!errorMessages) console.error('[ActivateAccountForm] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });
  const formikRef = useRef(formik);
  useUpdateRefIfShallowNew(formikRef, formik);

  useEffect(() => {
    if (personalInformationQuery.loading) {
      formikRef.current.setSubmitting(true);
    } else if (personalInformationQuery.data?.currentUser) {
      formikRef.current.setValues(
        {
          name: personalInformationQuery.data.currentUser.name,
          companyName: personalInformationQuery.data.currentUser.companyName,
          companyAddress:
            personalInformationQuery.data.currentUser.companyAddress,
          companyActivity:
            personalInformationQuery.data.currentUser.companyActivity,
          jobTitle: personalInformationQuery.data.currentUser.jobTitle ?? '',
          email: personalInformationQuery.data.currentUser.email,
          phoneNumber: personalInformationQuery.data.currentUser.phoneNumber,
          country: countryValues.find(
            (it) =>
              it.value.isoCode ===
              personalInformationQuery.data.currentUser.country
          ),
        },
        true
      );
      formikRef.current.setSubmitting(false);
    } else if (personalInformationQuery.error) {
      navigate('/');
    }
  }, [formikRef, personalInformationQuery]);

  useEffect(() => {
    if (
      formik.values.country &&
      !(
        formik.values.phoneNumber?.indexOf(
          formik.values.country.value.dialCode
        ) > -1
      )
    ) {
      formik.setFieldValue('phoneNumber', formik.values.country.value.dialCode);
    }
  }, [formik.values.country]);

  return (
    <Layout url="/profile/" title="Profile" disableIndex>
      <Menu currentPath={location.pathname}>
        <form
          onSubmit={formik.handleSubmit}
          className="flex-1 flex flex-col mb-0"
        >
          <div className="lg:pl-12 flex-1 flex flex-col">
            <h3 className="hidden lg:block font-emp text-2xl">Edit Profile</h3>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-4">
              <TextInput
                name="name"
                formik={formik}
                label="Name"
                className="col-span-1"
              />

              <TextInput
                name="companyName"
                formik={formik}
                label="Company Name"
                className="col-span-1 col-start-1"
              />

              <TextInput
                name="companyAddress"
                formik={formik}
                label="Company Address"
                className="col-span-1 lg:col-start-2"
              />

              <TextInput
                name="companyActivity"
                formik={formik}
                label="Company Activity"
                className="col-span-1 col-start-1"
              />

              <TextInput
                name="jobTitle"
                formik={formik}
                label="Job Title"
                className="col-span-1 lg:col-start-2"
              />
            </div>

            <hr className="mt-12 mb-6 bg-white opacity-10" />

            <h3 className="font-emp text-xl lg:text-2xl mt-0 mb-4 lg:mb-0">
              Contact Information
            </h3>
            <p className="text-interaction-disabled text-sm">
              Others will see this as contact information for the trades you
              create
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-4">
              <TextInput
                name="email"
                formik={formik}
                label="Email"
                className="col-span-1 col-start-1"
              />

              <SelectAndTextInput
                className="col-span-1 lg:col-start-2"
                formik={formik}
                label="Phone Number"
                select={{
                  name: 'country',
                  values: countryValues,
                  reactSelectProps: {
                    menuPlacement: 'top',
                    getOptionLabel: (countryValue: SelectInputValue) =>
                      countryValue.value?.name ?? '',
                  },
                }}
                text={{
                  name: 'phoneNumber',
                }}
              />
            </div>

            <div className="flex-1" />
            <div className="p-4 -mx-4 mt-8 lg:mt-20 lg:bg-transparent">
              <Button
                className="w-full"
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
              >
                Save Profile
              </Button>
            </div>
          </div>
        </form>
      </Menu>
    </Layout>
  );
};

export default ProfilePage;
