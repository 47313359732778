import { SecurityContext } from '@lib';
import clsx from 'clsx';
import { Link, navigate } from 'gatsby';
import * as React from 'react';
import { FC, useContext } from 'react';

import './Menu.css';
import { useUserStatusGuard } from './helpers';

type MenuProps = {
  currentPath: string;
};

const Menu: FC<MenuProps> = ({ children, currentPath }) => {
  useUserStatusGuard();
  const { logout } = useContext(SecurityContext);

  const menuItemClass = 'flex flex-row items-center';

  return (
    <div className="ProfileMenu-wrapper flex-1 flex flex-col lg:flex-row mt-8 lg:pb-10">
      <h1 className="lg:hidden text-center font-emp font-bold text-base -mt-4 mb-10">
        Profile
      </h1>
      <div className="ProfileMenu lg:bg-background-avg rounded-md lg:max-w-md mb-10 lg:mb-4 lg:p-4 lg:pr-16 text-sm font-emp">
        <ul className="m-0 flex flex-row lg:flex-col overflow-x-auto space-x-6 lg:space-x-0 lg:space-y-6 whitespace-nowrap h-full">
          <li
            className={clsx(
              menuItemClass,
              'lg:mb-0',
              /\/profile\/*$/.test(currentPath) && 'text-white active'
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            <Link className="no-underline relative" to="/profile/">
              Edit Profile
            </Link>
          </li>
          <li
            className={clsx(
              menuItemClass,
              /\/profile\/password\/*$/.test(currentPath) && 'text-white active'
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              {' '}
              <path
                fillRule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clipRule="evenodd"
              />
            </svg>
            <Link className="no-underline relative" to="/profile/password/">
              Password
            </Link>
          </li>

          <li
            className={clsx(
              menuItemClass,
              /\/profile\/notifications\/*$/.test(currentPath) &&
                'text-white active'
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
            </svg>
            <Link
              className="no-underline relative"
              to="/profile/notifications/"
            >
              Notifications
            </Link>
          </li>

          <li
            className={clsx(
              menuItemClass,
              /\/profile\/interests\/*$/.test(currentPath) &&
                'text-white active'
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
              <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
            </svg>
            <Link className="no-underline relative" to="/profile/interests/">
              Interests
            </Link>
          </li>

          {/*<li*/}
          {/*  className={clsx(*/}
          {/*    menuItemClass,*/}
          {/*    /\/profile\/billing\/*$/.test(currentPath) && 'text-white active'*/}
          {/*  )}*/}
          {/*>*/}
          {/*  <svg*/}
          {/*    xmlns="http://www.w3.org/2000/svg"*/}
          {/*    className="h-4 w-4 mr-2"*/}
          {/*    viewBox="0 0 20 20"*/}
          {/*    fill="currentColor"*/}
          {/*  >*/}
          {/*    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />*/}
          {/*    <path*/}
          {/*      fillRule="evenodd"*/}
          {/*      d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"*/}
          {/*      clipRule="evenodd"*/}
          {/*    />*/}
          {/*  </svg>*/}
          {/*  <Link className="no-underline relative" to="/profile/billing/">*/}
          {/*    Billing*/}
          {/*  </Link>*/}
          {/*</li>*/}

          <li
            className={clsx(
              menuItemClass,
              /\/profile\/delete-account\/*$/.test(currentPath) &&
                'text-white active'
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <Link
              className="no-underline relative"
              to="/profile/delete-account/"
            >
              Delete account
            </Link>
          </li>

          <li className={menuItemClass}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                clipRule="evenodd"
              />
            </svg>
            <a
              className="no-underline relative"
              href="/logout/"
              onClick={async (event) => {
                event.preventDefault();
                await logout();
                return navigate('/');
              }}
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="flex-1 flex flex-col">{children}</div>
    </div>
  );
};

export default Menu;
